<template>
  <div>
    <section class="main">
      <div class="hero parallax">
      </div>
      <article>
        <h2>Our Services</h2>
        <p class="intro">
          The most responsive and reliable <router-link to="/">security company</router-link> in the industry. At <router-link to="/">GammaTeam Security</router-link>, our mission is to ensure a peace of mind and provide the highest level of security services and <router-link to="/security-consulting">security consulting</router-link>. We guarantee smooth process of operations, quality, and control of our services by carefully selecting and professionally training our staff on the state level.<br /><br />

          Our company's culture is based on the achievement of excellence in every aspect of providing security and protection, and exceeding our customers' expectations and needs, with integrity and professionalism.<br /><br />

          Our foremost aspiration is to serve and protect <span class="weight-black">you</span>. We know that you are committed to your work, and we appreciate and respect everything you do. All of us at <router-link to="/">GammaTeam Security</router-link> are honored for the opportunity to partner with you and provide you with maximum standard of service in the security field. With that in mind, all our Security Officers are certified, insured, bonded and uniquely steadfast.
        </p>
        <div class="standards">
          They are to:
          <ul>
            <li>undergo criminal background check and fingerprinting</li>
            <li>go through the company's pre-employment screening before being hired</li>
            <li>participate in the training to upgrade their knowledge in state laws, patrol techniques and self-defense skills annually</li>
          </ul>
        </div>
        <p class="intro">
          Our services are designed for small businesses and large corporations with the commitment to highest quality, dedication, and maximum safety. If you feel that your current security program needs improvement, please allow us the opportunity to conduct a complementary security survey for you.
          The survey will allow us to give you an expert analysis of your property's safety and security program.
          Furthermore, if you are in need of a new program, we will develop an integrated plan to cover your needs and focus on recommendations that fit within your budget.
        </p>

        <div class="flex-container security-services">
          <aside v-for="(service, i) in services" :key="i">
            <div :style="'background-image: url(images/services/' + service.image + '.jpg); background-size: 100% 200px; width: 100%; height: 200px;'">
              <h2><router-link :to="'/services/' + service.location">{{  service.label }}</router-link></h2>
            </div>
            <button @click.stop="$router.push({ path: '/services/' + service.location + '#'});">LEARN MORE</button>
          </aside>
        </div>
      </article>

      <call-to-action :link_back=false></call-to-action>
    </section>
  </div>
</template>

<script>
import router from '@/router'
export default {
  name: 'Home',
  data: () => ({
    services: [
      { label: 'Healthcare Security', image: 'hospital', location: 'healthcare-security' },
      { label: 'Hospitality Security', image: 'hospitality', location: 'hospitality-security' },
      { label: 'Commercial Security', image: 'commercial', location: 'commercial-security' },
      { label: 'Education Security', image: 'education', location: 'education-security' },
      { label: 'Construction Site Security', image: 'construction', location: 'construction-site-security' },
      // { label: "Executive Security & VIP Protection", image: "vip", location: "executive-vip-security-protection " },
      // { label: "Cyber Security", image: "cyber", location: "cyber-security" },
      { label: 'Security Consulting', image: 'consulting', location: 'security-consulting' },
      { label: 'Special Event Security', image: 'event', location: 'special-event-security' }
    ]
  }),
  methods: {
    goto: function (path) {
      router.push({ path: path })
    }
  }
}
</script>

<style scoped>
section.main article div.standards  {
  letter-spacing: 0px;
  line-height: 1.6em;
  font-size: 1.2em;
  text-transform: full-width;
  margin-bottom: 30px;
}
section.main article div ul li {
  margin-left: 30px;
}
section.main article div.security-services {
  margin: 20px 0 50px 0;
}
section.main article div.security-services aside {
  margin: 20px 0 0 0;
}
section.main article div.security-services h2 {
  font-size: 1.2em;
  letter-spacing: 0px;
  background-color: rgba(0,0,0,.5);
  padding: 20px 0px;
  text-align: center;
  font-weight: 900;
  color: white;
}
section.main article div.security-services div {
  border-radius: 15px;
}
section.main article div.security-services button {
  display: block;
  float: none;
  margin: 20px auto 20px  auto;
}
</style>
